import { template as template_fcb076e0b4794b0d8f17a34db7ead3cf } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const TagNotificationsTracking = template_fcb076e0b4794b0d8f17a34db7ead3cf(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="tagging.notifications"
    class="tag-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TagNotificationsTracking;
