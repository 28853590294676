import { template as template_1037e2a7c86043d1a4f66efe43a745d6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1037e2a7c86043d1a4f66efe43a745d6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
