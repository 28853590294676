import { template as template_3d35fa37cb724c7a88f719e3af3c07e9 } from "@ember/template-compiler";
const FKText = template_3d35fa37cb724c7a88f719e3af3c07e9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
