import { template as template_d3fd4916a4a249d493a5447b92bf8634 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_d3fd4916a4a249d493a5447b92bf8634(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
